import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { Colours } from "../colours";
import Head from "next/head";

export default function Custom404() {
  const router = useRouter();

  useEffect(() => {
    // Redirect to home page after 5 seconds
    const timeout = setTimeout(() => {
      router.push("/");
    }, 5000);

    // Clean up timeout
    return () => clearTimeout(timeout);
  }, [router]);

  return (
    <>
      <Head>
        <title>Not Found</title>
      </Head>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <h1>404 - Page Not Found</h1>
        <p>The page you're looking for does not exist.</p>
        <p>
          Redirecting to{" "}
          <Link href="/" style={{ color: Colours.darkBlue }}>
            home page
          </Link>
          ...
        </p>
      </div>
    </>
  );
}
